import React from 'react'
import './style.css';
import "../../Components/MainContent/Popular/Popular.css"
import { Link } from 'react-router-dom';

const Menu = ({ items }) => {
  return (
   
            
    <div className="container">
    <div className='row '>
    <div className='col-md-12 '>
      {items.map((menuItem) => {
        const { id, title, img, paragraph, category} = menuItem
        return (
          

          <article key={id} className="menu-item ">
                 <div className="container popular" >
                            <div className="row shadow" >
                                <div className="col-md-5 d-flex bg-dark ">
                                 
                                <div className='images row' style={{height:"170px"}}>
                     
                        <img src={img} alt={title}  style={{height:"170px", width:'300px'}}/>
                    
                      
                                </div>
                                </div>
                                <div className="col-md-7">
                                <div className='text row'>
                                <div class='category category1'>
                        <span>{category}</span>
                      </div>
                                <Link className='text-dark' to={`/SinglePage/${id}`}>
                      <h5 className='mt-3 '>{title.slice(0,68)}</h5>
                      </Link>
                      <p>{paragraph.slice(0,75)}...</p>
                      {/* <div className='date'>
                        <i class='fas fa-calendar-days'></i>
                        <label>{val.date}</label>
                      </div>
                      <div className='comment'>
                        <i class='fas fa-comments'></i>
                        <label>{val.comments}</label>
                      </div> */}
                    </div>
                                </div>

                            </div>
                            <br/>
                    </div>
           
          </article>
         
            
            
          )
        })}
        </div>
       
        </div>
    </div>
  )
}

export default Menu
