import React from 'react'
import { Link } from 'react-router-dom'

const Categories = ({ categories }) => {
  return (
    <div>
      
          <div>
            <div className="header_bottom">
      <div className="container-fluid ">
        <nav className="navbar navbar-expand-lg custom_nav-container ">
            <div className='container-fluid '>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className> </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {categories.map((category, index) => {
        return (
            <ul className="navbar-nav m-auto " key={index} >
       
            <li className="nav-item" >
                <Link className="nav-link font-semibold" to={`/${category.id}`}> {category}</Link>
              </li>
              
          
             
            </ul>
             )
            })}
          </div>
          </div>
        </nav>
      </div>
    </div>
         </div>
         </div>
  )
}

export default Categories
