import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
      <footer class="text-gray-600 body-font">
        
  <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
    <Link to='/' class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
      <span class="ml-3 text-xl">News</span>
    </Link>
    <p class="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2024 News —
      <Link to="/" class="text-gray-600 ml-1" rel="noopener noreferrer" target="_blank">@All Rights Reserved And subject to Terms of Use Agreement</Link>
    </p>
    <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
      <Link to='/' class="text-gray-500">
      <i class="fa-brands fa-square-facebook"></i>
      </Link>
      <Link to='/' class="ml-3 text-gray-500">
      <i class="fa-brands fa-square-instagram"></i>
      </Link>
      <Link to='/' class="ml-3 text-gray-500">
      <i class="fa-brands fa-square-x-twitter"></i>
      </Link>
     
    </span>
  </div>
</footer>
    </div>
  )
}

export default Footer
