import React from 'react'
import { opinion } from './../../../Data/newsdata';
import './side.css'
const Opinion = () => {
  return (
    <div>
      <div className='opinion'>
        <div className='content'>

        <div className='container'>
            <div className='row mt-5 '>
<div className='col-md-12 py-5' style={{backgroundColor:"#FAFAFA"}}>          
          <div class="container section-wrapper white-smoke-opacity-bg has-padding hide-for-large">
            <div class="block-title text-center first-letter-style ">
              <h3><a href="/opinion" style={{textDecoration:"none", color:"#000"}}>Opinion</a></h3>
            </div>
            <hr className='mx-auto mt-2 ' style={{width:"80px", backgroundColor:"#9a050c", height:"1px"}}/>
            <div class="block-content" data-vr-zone="Opinion Mobile">
            {opinion.map((ele) => {
    return(

              <div class="view view-blocks-nodequeue view-id-blocks_nodequeue view-display-id-block_2 view-dom-id-879356f5ddbcaa6dcabe31960579d53d">
        
  
  
      <div class="view-content">
      <div class="objects-list has-separator">
			<div class="object-item ">
    <div class="media-object align-justify d-flex">
        <div class="media-object-section">
            <div class="author-name"><a href="/taxonomy/term/92206" style={{textDecoration:"none", color:"#9a050c"}}>{ele.name}</a></div>
            <h4 class="font-semibold ft-sz-16 emperor-font-color mt-1">
                <a href="/node/2545431"  style={{textDecoration:"none", color:"#000"}}>{ele.desc}</a>
            </h4>
        </div>
                <div class="media-object-section ">
            <div class="media-object-img ">
                <a href="/taxonomy/term/92206">
                  <img src={ele.img} width="65" height="85" alt="Author" />                </a>
            </div>
        </div>
            </div>
            <hr className='mx-auto mt-2 ' style={{width:"200px", backgroundColor:"#ccc", height:"1px"}}/>
</div>

	</div>    </div>
  
</div> 
)})}           
  <div class="btns-area text-center uppercase mt-3">
                <a href="/opinion" class="button hollow small gray no-spacer"><button className='m-auto btn btn-sm '>More in options</button></a>
              </div>
            </div>
          </div>
</div>
</div>

        </div>

    

        </div>

      </div>
    </div>
  )
}

export default Opinion
