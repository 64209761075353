import './App.css';
import {  BrowserRouter ,  Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import SinglePage from './Components/SinglePage/Singlepage';
import Newspdf from './Pages/Newspdf';
import Latestnews from './Pages/Latestnews';
import DetailsNews from './Components/SinglePage/DetailsNews';
import Signin from './Pages/signin/Signin';
import Protectedroutes from './Components/layout/Protectedroutes';
import Dashboard from './Components/dashboard/Dashboard';

function App() {
  return (
    <div className="w-full">
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path='/singlepage/:id' element={<SinglePage/>} />
      <Route path="/latestnews" element={<Latestnews/>}/>
      <Route path="/newspdf" element={<Newspdf/>}/>
      <Route path='/:Id' element={<DetailsNews/>} />
      <Route path="/signin" element={<Signin />} />

      <Route path='/' element={<Protectedroutes />}/>
  <Route path='/dashboard' element={< Dashboard />} />
      </Routes>
    </BrowserRouter>
  </div>
  );
}

export default App;
