import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'

const Signin = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const navigate = useNavigate();
    const login = (e) =>{
        e.preventDefault();
        if (email==="admin"&&password==="admin") {
            localStorage.setItem('user',JSON.stringify({email, password}))
            navigate('/dashboard')
            
        } else {
            navigate('/signin')
        }
     }
    return (
        <>
        <section className="bg-primary py-3 py-md-5 py-xl-8 ">
            <div className="container py-5">
                <div className="row gy-4 align-items-center">
                    <div className="col-12 col-md-6 col-xl-7">
                        <div className="d-flex justify-content-center text-bg-primary">
                            <div className="col-12 col-xl-9">
                                {/* <img className="img-fluid rounded mb-4" loading="lazy" src="./assets/img/bsb-logo-light.svg" width={245} height={80} alt="BootstrapBrain Logo" /> */}
                                <Link to="/" className='text-light'>News Logo</Link>
                                <hr className="border-primary-subtle mb-4" />
                                <h2 className="h1 mb-4">Admin's Panel</h2>
                                <p className="text-light mb-5">Only registered user can access this panel.</p>
                                <div className="text-endx">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="currentColor" className="bi bi-grip-horizontal" viewBox="0 0 16 16">
                                        <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-5">
                        <div className="card border-0 rounded-4">
                            <div className="card-body p-3 p-md-4 p-xl-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mb-4">
                                            <h3>Sign in</h3>
                                            {/* <p>Don't have an account? <a href="#!">Sign up</a></p> */}
                                        </div>
                                    </div>
                                </div>
                                <form action="#!">
                                    <div className="row gy-3 overflow-hidden">
                                        <div className="col-12">
                                            <div className="form-floating mb-3">
                                                <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" required onChange={(e) => setEmail(e.target.value)}/>
                                                <label htmlFor="email" className="form-label">Email</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating mb-3">
                                                <input type="password" className="form-control" name="password" id="password" placeholder="Password" required onChange={(e) => setPassword(e.target.value)}/>
                                                <label htmlFor="password" className="form-label">Password</label>
                                            </div>
                                        </div>
        
                                        <div className="col-12">
                                            <div className="d-grid">
                                                <button className="btn btn-primary btn-lg" type="submit" onClick={login}>Log in now</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        </>
    )
}

export default Signin