import React from 'react'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
    // let auth = localStorage.getItem('user')
    const navigate = useNavigate()

    const logout = () => {
        localStorage.clear()
        navigate('/signin')
    }
    return (
        <div className='text-center fs-3 '>Welcome to Dashboard <br /><br /><br />  
            <button onClick={logout}>log out</button>
        </div>
    )
}

export default Dashboard