import React from 'react'
import { Link } from 'react-router-dom'
// import "./style.css"

const SinglePageSlider = (props) => {
   
  return (
    <div>
  <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
    <Link to='https://www.youtube.com/watch?v=HAyHfn2HWeY'>  <img className="d-block w-100" src={props.img1} alt="First slide" style={{height:"300px"}} /></Link>
    </div>
    <div className="carousel-item">
    <Link to='https://www.youtube.com/watch?v=t0Q2otsqC4I'> <img className="d-block w-100" src={props.img2} alt="Second slide" style={{height:"300px"}}/></Link>
    </div>
    <div className="carousel-item">
    <Link to='https://www.youtube.com/watch?v=b_EWS9bNDhg'> <img className="d-block w-100" src={props.img3} alt="Third slide" style={{height:"300px"}}/></Link>
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="sr-only">Next</span>
  </a>
</div>

    </div>
  )
}

export default SinglePageSlider
