import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'

const Protectedroutes = () => {

    let auth = localStorage.getItem('user')

  return (
    <div>
        {auth ? <Outlet /> : <Navigate to="/signin" />}
    </div>
  )
}

export default Protectedroutes