import React from 'react'
import { focus } from './../../../Data/newsdata';
import './side.css'
const Focus = () => {
  return (
    <div>
      <div className='focus'>
        <div className='content'>
            {focus.map((ele)=>{
                return(
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12 py-5' style={{backgroundColor:"#FAFAFA"}}>
                                <div class="view-content">
      	<div class="block-wrapper block-custom-bg has-padding">
    <div class="block-title text-center first-letter-style style-v4">
        <h3><span>{ele.heading}</span></h3>
    </div>
    <hr className='mx-auto mt-2 ' style={{width:"80px", backgroundColor:"#9a050c", height:"1px"}}/>
    <div class="block-content">
        <h5 class="text-center uppercase font-secondary bottom-spacer--s"><a href="/taxonomy/term/78691" style={{textDecoration:"none", color:"#000"}}><span class="field-content text-dark" >{ele.title}</span></a></h5>
        <div class="view view-spotlight view-id-spotlight view-display-id-block_2 view-dom-id-ec8e4790d0c1c1786c4d8b846c8f450f">
        
  
  
      <div class="view-content">
      	    <div class="article-item" data-nid="2544631" data-vr-contentbox="position 1">
                    <div class="article-item-img text-center">
                                <a href="/node/2544631">
                    <span class="views-count abs-el views-count-placeholder nid-2544631"><i class="icomoon-icon icon-views"></i> <span></span></span>
                    <img src={ele.coverimg} width="410" height="200" alt="" />
                     
                         </a>
            </div>
                <div class="article-item-info">
            <div class="article-item-title ">
                <h5 class="font-bold "><a href="/node/2544631" style={{textDecoration:"none", color:"#000"}}>{ele.title1}</a></h5>
            </div>
                            <div class="article-item-highlight">
                    <ul>
                                                  <li>{ele.description}</li>
                                           </ul>
                </div>
                        <div class="article-item-meta">

                                    <span class="meta-author">By 
                        <a href="/taxonomy/term/124566" style={{textDecoration:"none", color:"#000"}}> {ele.from}</a>                    </span>
                    &middot;
                                    <time>{ele.date}</time> 
                            </div>
        </div>
    </div>
    
   
    <div class="objects-list">
    	    <div class="object-item" data-nid="2539661" data-vr-contentbox="position 2">
               
        <div class="media-object d-flex">
            <div class="media-object-section">
                <h6><a href="/node/2539661" style={{textDecoration:"none", color:"#000"}}>Pakistan deputy PM meets Saudi envoy, appreciates facilities for pilgrims during Hajj</a></h6>
            </div>
            <div class="media-object-section">
                                <div class="media-object-img">
                    <a href="/node/2539661">
                        <img src="https://www.arabnews.pk/sites/default/files/styles/n_100_59/public/main-image/2024/06/28/4440586-61229734.jpg?itok=4EUi7Ji5" width="100" height="59" alt="" />                    </a>
                </div>
                                <div class="meta-area"><span class="views-count views-count-placeholder nid-2539661"><i class="icomoon-icon icon-views"></i> <span></span></span></div>
            </div>
        </div>
        
    </div><br/>
	    <div class="object-item" data-nid="2537401" data-vr-contentbox="position 3">
        <div class="media-object d-flex">
            <div class="media-object-section">
                <h6><a href="/node/2537401" style={{textDecoration:"none", color:"#000"}}>Pakistan cabinet approves draft MoU for student, teacher exchanges with Saudi Arabia</a></h6>
            </div>
            <div class="media-object-section">
                                <div class="media-object-img">
                    <a href="/node/2537401">
                        <img src="https://www.arabnews.pk/sites/default/files/styles/n_100_59/public/main-image/2024/06/25/4434651-1587220230.jpg?itok=HmqnghaA" width="100" height="59" alt="" />                    </a>
                </div>
                                <div class="meta-area"><span class="views-count views-count-placeholder nid-2537401"><i class="icomoon-icon icon-views"></i> <span></span></span></div>
            </div>
        </div>
    </div>
</div>   

 </div>
  
  
  </div>
  </div>
  </div>
  
  
</div>
<div className=' text-center py-3'>

<button className='m-auto btn btn-sm '>Read more</button>
</div>
                                    </div>
                                </div>

                        </div>
                )
            })}

        </div>
      </div>
    </div>
  )
}

export default Focus
