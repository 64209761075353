import React from 'react'
import Focus from './Focus'
import Opinion from './Opinion'
import Recommended from './Recommended'
import SinglePageSlider from '../../SinglePage/Slider/SinglePageSlider'
import { cartoon, gallery } from '../../../Data/newsdata'
import { Link } from 'react-router-dom'


const Side = () => {
 
   
 
  return (
    <div>
     
      <div className='container'>
      <div className='row   justify-content-center'>
  <div className='col-md-12' style={{backgroundColor:"#FAFAFA"}}>
          <div className="container text-center block-wrapper show-for-large ">
            <div className="block-title  first-letter-style py-4 ">
              <h3 style={{textDecoration:"none", color:"#000", fontFamily:"Time New Roman", fontSize:"40px",fontWeight:"bold"}}><span>News</span></h3>
              <hr className='mx-auto mt-2 ' style={{width:"150px", backgroundColor:"#9a050c", height:"1px"}}/>
            </div>
            
            <div className="block-content" data-vr-zone="Print Edition">
              <div className="print-edition text-center">
                <div className="view view-newspaper view-id-newspaper view-display-id-block view-dom-id-8a7bc57efca3da4955cad20210e315a8">
      <div className="view-content">
      <div className="print-edition-cover">
			<Link to="/newspdf"><img src="../images/news.jpeg" width="410" height="497" alt="" /></Link> 	</div>    </div> 
</div>                <div className="print-edition-footer py-4 ">
                  <Link  to="/newspdf" style={{textDecoration:"none", color:"#000"}}><p className='color'>Read pdf version</p></Link>
                  <hr className='mx-auto mt-2 ' style={{width:"120px", backgroundColor:"#ccc", height:"1px"}}/>
                   
                </div>
              </div>
           
            </div>
          </div>
  </div>
</div>
      </div>

      {/* for cartoon */}
      <div className='container'>
        <div className='row mt-5 justify-content-center' >
        <div className='col-md-12 pb-5' style={{backgroundColor:"#FAFAFA"}}>
          <div className="container text-center block-wrapper show-for-large ">
            <div className="block-title  first-letter-style py-4 ">
              <h3 style={{textDecoration:"none", color:"#000", fontFamily:"Time New Roman", fontSize:"40px",fontWeight:"bold"}}><span>Cartoon</span></h3>
              <hr className='mx-auto mt-2 ' style={{width:"150px", backgroundColor:"#9a050c", height:"1px"}}/>
            </div>
      
<SinglePageSlider  {...cartoon}/>
</div>

</div>

        </div>
      </div>
      

      {/* for pictures */}
      <div className='container'>
        <div className='row my-5 justify-content-center' >
        <div className='col-md-12 pb-5' style={{backgroundColor:"#FAFAFA"}}>
          <div className="container text-center block-wrapper show-for-large ">
            <div className="block-title  first-letter-style py-4 ">
              <h3 style={{textDecoration:"none", color:"#000", fontFamily:"Time New Roman", fontSize:"40px",fontWeight:"bold"}}><span>Pictures</span></h3>
              <hr className='mx-auto mt-2 ' style={{width:"150px", backgroundColor:"#9a050c", height:"1px"}}/>
            </div>
       
      
            <SinglePageSlider  {...gallery}/>
</div>

</div>
        </div>
      </div>
      <Focus/>
      <Opinion/>
      <Recommended/>
    </div>
  )
}

export default Side
