import React from 'react'
import Navbar from '../Header/Navbar'
import Side from '../MainContent/Side/Side'
import Layout from '../layout/Layout'
// import { useParams } from 'react-router-dom';
// import { popular } from '../../Data/newsdata'

const DetailsNews = () => {
  // const { Id } = useParams()
  // if we use === below then we have to save id as a string in cardapi because === match its type as well 
  // let filterData = popular.find(x => x.id === Id)

  return (
    <Layout>
       <Navbar/>
      
      <div className='container'>
        <div className='row'>
          <div className='col-md-7 '>
<div className='d-flex mt-5'>
<hr style={{width:"250px" }}/><h3 className='m-auto font-bold'>Pakistan</h3><hr style={{width:"250px"}}/>
</div>
<img className='my-5' src='./images/bussiness/b2.jpg' alt=''/>
<div>
  <p>
    The court expressed concern, stating, "It's been three months since two people went missing forcibly. We can only imagine what their families are going through. People are being picked up, and the Chief Executive is doing nothing.\" The court then asked, \"Additional Attorney General, did anything come out of the prime minister and attorney general's meeting?\" During the hearing, Awan requested the court to summon the prime minister, to which the court responded, \"The PM is the head of state as per the constitution, and the attorney general is the head of the law...we summoned the attorney general in accordance with due process.\" Justice Aurangzeb remarked, \"If the government does not want to follow due process, what can be said?\" \"They don't realize the damage being done to the country's reputation. 
    <br/> &nbsp; &nbsp; &nbsp; &nbsp; I'm keeping this case until Tuesday, but I won't be available then\". \"We don't want any delays in this case due to my absence.\" The court directed the head of the JIT, SP Lahore, to submit reports and stated that the police must investigate how non-police personnel managed to wear police uniforms. At this point, Babar Awan requested the court to issue a strict order, to which Justice Aurangzeb remarked, \"I will pass an order on this case.<br/>\" During the same hearing, the court summoned the Inspector General (IG) of Islamabad Police regarding the disappearance of a PTI worker, Faizan. Justice Miangul Hassan Aurangzeb presided over the case filed for Faizan's recovery. The court questioned what progress the investigating officer had made over the past two weeks, inquiring whether the officer had visited the location from where Faizan disappeared or met with his father. The court asked if the state counsel was satisfied with the investigation report, to which the counsel responded that a detailed report would be presented at the next hearing. Islamabad Police informed the court that they had conducted investigations at all the places Faizan was known to frequent. Subsequently, due to the lack of progress in recovering Faizan, the IHC ordered the IG Islamabad to appear before the court at the next hearing, scheduled for Tuesday.
  </p>
</div>
<img className='my-5' src='./images/bussiness/b3.jpg' alt=''/>
<div>
  <p>
    The court expressed concern, stating, "It's been three months since two people went missing forcibly. We can only imagine what their families are going through. People are being picked up, and the Chief Executive is doing nothing.\" The court then asked, \"Additional Attorney General, did anything come out of the prime minister and attorney general's meeting?\" During the hearing, Awan requested the court to summon the prime minister, to which the court responded, \"The PM is the head of state as per the constitution, and the attorney general is the head of the law...we summoned the attorney general in accordance with due process.\" Justice Aurangzeb remarked, \"If the government does not want to follow due process, what can be said?\" \"They don't realize the damage being done to the country's reputation. 
    <br/> &nbsp; &nbsp; &nbsp; &nbsp; I'm keeping this case until Tuesday, but I won't be available then\". \"We don't want any delays in this case due to my absence.\" The court directed the head of the JIT, SP Lahore, to submit reports and stated that the police must investigate how non-police personnel managed to wear police uniforms. At this point, Babar Awan requested the court to issue a strict order, to which Justice Aurangzeb remarked, \"I will pass an order on this case.<br/>\" During the same hearing, the court summoned the Inspector General (IG) of Islamabad Police regarding the disappearance of a PTI worker, Faizan. Justice Miangul Hassan Aurangzeb presided over the case filed for Faizan's recovery. The court questioned what progress the investigating officer had made over the past two weeks, inquiring whether the officer had visited the location from where Faizan disappeared or met with his father. The court asked if the state counsel was satisfied with the investigation report, to which the counsel responded that a detailed report would be presented at the next hearing. Islamabad Police informed the court that they had conducted investigations at all the places Faizan was known to frequent. Subsequently, due to the lack of progress in recovering Faizan, the IHC ordered the IG Islamabad to appear before the court at the next hearing, scheduled for Tuesday.
  </p>
</div>
<img className='my-5' src='./images/bussiness/b4.jpg' alt=''/>
<div>
  <p>
    The court expressed concern, stating, "It's been three months since two people went missing forcibly. We can only imagine what their families are going through. People are being picked up, and the Chief Executive is doing nothing.\" The court then asked, \"Additional Attorney General, did anything come out of the prime minister and attorney general's meeting?\" During the hearing, Awan requested the court to summon the prime minister, to which the court responded, \"The PM is the head of state as per the constitution, and the attorney general is the head of the law...we summoned the attorney general in accordance with due process.\" Justice Aurangzeb remarked, \"If the government does not want to follow due process, what can be said?\" \"They don't realize the damage being done to the country's reputation. 
    <br/> &nbsp; &nbsp; &nbsp; &nbsp; I'm keeping this case until Tuesday, but I won't be available then\". \"We don't want any delays in this case due to my absence.\" The court directed the head of the JIT, SP Lahore, to submit reports and stated that the police must investigate how non-police personnel managed to wear police uniforms. At this point, Babar Awan requested the court to issue a strict order, to which Justice Aurangzeb remarked, \"I will pass an order on this case.<br/>\" During the same hearing, the court summoned the Inspector General (IG) of Islamabad Police regarding the disappearance of a PTI worker, Faizan. Justice Miangul Hassan Aurangzeb presided over the case filed for Faizan's recovery. The court questioned what progress the investigating officer had made over the past two weeks, inquiring whether the officer had visited the location from where Faizan disappeared or met with his father. The court asked if the state counsel was satisfied with the investigation report, to which the counsel responded that a detailed report would be presented at the next hearing. Islamabad Police informed the court that they had conducted investigations at all the places Faizan was known to frequent. Subsequently, due to the lack of progress in recovering Faizan, the IHC ordered the IG Islamabad to appear before the court at the next hearing, scheduled for Tuesday.
  </p>
</div>
<img className='my-5' src='./images/bussiness/b5.jpeg' alt=''/>
<div>
  <p>
    The court expressed concern, stating, "It's been three months since two people went missing forcibly. We can only imagine what their families are going through. People are being picked up, and the Chief Executive is doing nothing.\" The court then asked, \"Additional Attorney General, did anything come out of the prime minister and attorney general's meeting?\" During the hearing, Awan requested the court to summon the prime minister, to which the court responded, \"The PM is the head of state as per the constitution, and the attorney general is the head of the law...we summoned the attorney general in accordance with due process.\" Justice Aurangzeb remarked, \"If the government does not want to follow due process, what can be said?\" \"They don't realize the damage being done to the country's reputation. 
    <br/> &nbsp; &nbsp; &nbsp; &nbsp; I'm keeping this case until Tuesday, but I won't be available then\". \"We don't want any delays in this case due to my absence.\" The court directed the head of the JIT, SP Lahore, to submit reports and stated that the police must investigate how non-police personnel managed to wear police uniforms. At this point, Babar Awan requested the court to issue a strict order, to which Justice Aurangzeb remarked, \"I will pass an order on this case.<br/>\" During the same hearing, the court summoned the Inspector General (IG) of Islamabad Police regarding the disappearance of a PTI worker, Faizan. Justice Miangul Hassan Aurangzeb presided over the case filed for Faizan's recovery. The court questioned what progress the investigating officer had made over the past two weeks, inquiring whether the officer had visited the location from where Faizan disappeared or met with his father. The court asked if the state counsel was satisfied with the investigation report, to which the counsel responded that a detailed report would be presented at the next hearing. Islamabad Police informed the court that they had conducted investigations at all the places Faizan was known to frequent. Subsequently, due to the lack of progress in recovering Faizan, the IHC ordered the IG Islamabad to appear before the court at the next hearing, scheduled for Tuesday.
  </p>
</div>
          </div>
          <div className='col-md-5 mt-5'>
            
<Side/>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DetailsNews
