import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {  popular } from "../../Data/newsdata" 
// import Side from "../home/sideContent/side/Side"
// import "../home/mainContent/homes/style.css"
import "./singlepage.css"
// import "../home/sideContent/side/side.css"
// import SinglePageSlider from "./Slider/SinglePageSlider"
import Side from "../MainContent/Side/Side"
import Navbar from './../Header/Navbar';

const SinglePage = () => {
  const { id } = useParams()
  const [item, setItem] = useState(null)
  // const [news,setNews]=useState(null)

  useEffect(() => {
    const item = popular.find((item) => item.id === parseInt(id))
    window.scrollTo(0, 0)
    if (item) {
      setItem(item)
    }
  }, [id])

 


//   useEffect(() => {
//      const news = hero.find((news) => news.id === parseInt(id))
//   window.scrollTo(0, 0)
//   if (news) {
//     setNews(news)
//   }
// }, [id])

  return (
    <>
    <Navbar/>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8 '>
          {item ? (
        <main>
          {/* <SinglePageSlider/> */}
          <div className='container'>
            <section className='mainContent details'>
           
              <h2 className=" text-left mx-3 item-heading" >{item.title}</h2>
              <div className="container">
                <div className="row ">
                <div class="col-md-12">

<div class="full ">

<img src={item.img} style={{height:"400px", width:"100%"}} alt=""/>
<p style={{marginTop:"-20px"}}>{item.title}</p>
  {/* <img src={item.title} style={{height:"435px"}} alt=""/> */}
</div>
</div>

                </div>
              </div>

              {/* <div className='author'>
              
                <p>by {item.authorName} </p>
               
              </div> */}
<div className="container-fluid">
  <div className="row">
    <div className="col-md-2  text-center mt-4 ">
   
                <p className="text-dark font-semibold" style={{fontSize:"10px",fontFamily:"sans-serif"}}>Updated<br/> {item.time} </p>               
             
              <hr className="text-dark"/>
              <div >
              <span className="" style={{fontSize:"22px",}}><i class="fa-brands fa-square-facebook"></i></span>
              </div>
              <div >
              <span className="" style={{fontSize:"22px",}}><i class="fa-brands fa-square-instagram"></i></span>
              </div>
              <div >
              <span className="" style={{fontSize:"22px",}}><i class="fa-brands fa-square-x-twitter"></i></span>
              </div>
              <div >
              <span className="" style={{fontSize:"22px",}}><i class="fa-brands fa-square-whatsapp"></i></span>
              </div>
              <div >
              <span className="" style={{fontSize:"22px",}}><i class="fa-solid fa-envelope"></i></span>
              </div>
    </div>
    <div className="col-md-10">
    <div className='desctop'>
              <span className="bold">{item.bold}</span>
                {item.desc.map((val) => {
                  return (
                    <>
                   
                      <p id="line" >{val.para1}</p>
                      <p  id="line">{val.para2}</p>
                    
                    </>
                  )
                })}
              </div>

              <img src={item.img} alt='' style={{height:"300px",width:"100%"}}/>
              <div className='desctop'>
                {item.desc.map((val) => {
                  return (
                    <>
                    
                      {/* <img src={val.cover} alt=""/> */}
                      <p id="line">{val.para3}</p>
                      <p id="line">{val.para4}</p>
                    </>
                  )
                })}
              </div>
</div>
  </div>
</div>
              
              <div className="desctop">
{/* <p>{item.desc}</p> */}
              </div>
              
            </section>
           
          </div>
        </main>
      ) : (
        <h1>not found</h1>
      )}
          </div>
          <div className='col-md-4 '>
            
<Side/>
          </div>
        </div>
      </div>
     
    </>
  )
}

export default SinglePage
