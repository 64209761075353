import React, { useState } from 'react'
import { popular } from '../Data/newsdata'
import Categories from '../Components/Latest/Categories'
import '../Components/Latest/style.css'
import Side from '../Components/MainContent/Side/Side'
import Menu from '../Components/Latest/Menu'
import { Link } from 'react-router-dom'
import Layout from '../Components/layout/Layout'

const allCategories = ['all', ...new Set(popular.map((popular) => popular.category))]
const Latestnews = () => {
    const [menuItems, setMenuItems] = useState(popular)
    const categories = allCategories

    const filterItems = (category) => {
      if (category === 'all') {
        setMenuItems(popular)
        return
    }
    const newItems = popular.filter((popular) => popular.category === category)
    setMenuItems(newItems)
  }

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 21;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = menuItems.slice(firstIndex, lastIndex);
  
  const npage = Math.ceil(menuItems.length / recordsPerPage);  
  const numbers = [...Array(npage + 1).keys()].slice(1); 
  const prePage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  const changeCPage = (n) => {
    setCurrentPage(n);
  };

  const nextPage = () => {
    if (currentPage !== npage) setCurrentPage(currentPage + 1);
  };
 
  return (
    <Layout>
         <main style={
      {backgroundColor:"#fff"}
    }>
    <section className="menu section " >
      <div className="title">
        <h2>Latest News</h2>
        <div className="underline mb-3"></div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
<Categories categories={categories} filterItems={filterItems}/>
          </div>
        </div>
      </div>
      {/* <div className='container'>
          <div className='row '>
            <div className='col-md-12'>
            <Categories categories={categories} filterItems={filterItems} />
            </div>
          </div>

        </div> */}
      <div className='container'>
        <div className='row'>
          <div className='col-md-8'>

      <Menu
       items={records} />
          </div>
          <div className='col-md-4 '>
            <Side/>


        </div>
        
      </div>
      </div>
      
    </section>
  </main>

    {/* //pagination */}
    <div className='container'>
    <nav>
        <ul className='pagination '>
          <li className='page-item'>
            <Link to='/latestnews' className='page-link text-dark' onClick={prePage}>Prev</Link>
          </li>
          {
            numbers.map((n, i) => (
              <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                <Link to='/latestnews' className='page-link text-dark' onClick={() => changeCPage(n)}>{n}</Link>
              </li>
            ))
          }
          <li className='page-item'>
            <Link to='/latestnews' className='page-link text-dark' onClick={nextPage}>Next</Link>
          </li>
        </ul>
      </nav>

    </div>
    </Layout>
  )
}

export default Latestnews
