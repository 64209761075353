import React from "react"
import { Link } from "react-router-dom"

const Card = ({ item: { id, img, category, title, authorName, time ,link} }) => {
  return (
    <>
      <div className='box '>
        <div className='img'>
        <Link to={link}><img src={img} alt='' />
          </Link>
        </div>
        <div className='text'>
          <span className='category'>{ category}</span>
         
          <Link to={`/SinglePage/${id}`}>
            <h1 className='titleBg'>{title}</h1>
          </Link>
          <div className='author flex'>
            <span>by {authorName}</span>
            <span>{time}</span>
          </div>
        </div>
      </div>
    
    </>
  )
}

export default Card
