import React  from 'react'
import { popular } from './../../Data/newsdata';
import Categories from './../Latest/CategoriesNav';

const allCategories = [ ...new Set(popular.map((popular) => popular.category))]
const Navbar = () => {
    const categories = allCategories
    
  return (
    <div>
         <main style={
      {backgroundColor:"#fff"}
    }>
    <section className="menu section">
      <Categories categories={categories}  />
      
    </section>
  </main>
    </div>
  )
}

export default Navbar
