import React from 'react'
import { Link } from 'react-router-dom'
import './header.css'

const Header = () => {



  return (
    <div>
      <div className='container-fluid text-center mt-3'>
        <div className='row'>

          <div className='col-lg-11 col-md-11 col-sm-11'>
            <h3><Link to='/' className='text-dark font-bold'>News Logo</Link></h3>
          </div>

          <div className='col-lg-1 col-md-1 col-sm-1 '>
            <Link to='/signin'>Sign In</Link>
          </div>

        </div>
      </div>
      <hr style={{ backgroundColor: "#9a050c" }} />

    </div>
  )
}

export default Header
