import React from 'react'
import Navbar from '../Components/Header/Navbar'
import Layout from '../Components/layout/Layout'

const Newspdf = () => {
  return (
    <Layout>
      <Navbar/>
      <div className='container '>

<embed className='text-center' src='../images/publication.pdf' height="600" width="1000px"/>
</div>
    </Layout>
  )
}

export default Newspdf
