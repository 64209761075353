import React, { useState } from 'react'
import Head from '../Components/Header/Head'
import Hero from '../Components/Hero/Hero'
import Side from '../Components/MainContent/Side/Side'
import Navbar from '../Components/Header/Navbar'
import Menu from '../Components/Latest/Menu'
import { popular } from '../Data/newsdata'
import { Link } from 'react-router-dom'
import Layout from '../Components/layout/Layout'

const Home = () => {
  
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 25;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = popular.slice(firstIndex, lastIndex);
  
  const npage = Math.ceil(popular.length / recordsPerPage);  
  const numbers = [...Array(npage + 1).keys()].slice(1);  

  const prePage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  const changeCPage = (n) => {
    setCurrentPage(n);
  };

  const nextPage = () => {
    if (currentPage !== npage) setCurrentPage(currentPage + 1);
  };
     
  return (
    <Layout>
      <Navbar/>
      <Head/>
      <Hero/>
      <div className='container'>
        <div className='row'>
      
          <div className='col-md-7 '>
            <Menu items={records}/>
            <div className="container">

<nav>
        <ul className='pagination'>
          <li className='page-item '>
            <Link to='/' className='page-link text-dark' onClick={prePage}>Prev</Link>
          </li>
          {
            numbers.map((n, i) => (
              <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                <Link to='/' className='page-link text-dark' onClick={() => changeCPage(n)}>{n}</Link>
              </li>
            ))
          }
          <li className='page-item'>
            <Link to='/' className='page-link text-dark' onClick={nextPage}>Next</Link>
          </li>
        </ul>
      </nav>  
</div>

{/* <Popular/> */}
          </div>
          <div className='col-md-5 '>
            
<Side/>
          </div>
        </div>
      </div>
      <div>
    


      </div>
    </Layout>
  )
}

export default Home
