import React from 'react'
import { Link } from 'react-router-dom'

const Head = () => {
  return (
    <div>
      <div className="container ">
    <div className="section-heading col-12 mt-5 text-center">
      <div className="col-md-12 mt-2 text-center ">
        <h2 className="display-2 mb-4 custom-heading ">
        <Link to='latestnews' style={{textDecoration:"none", color:"#000"}}> <span className="highlight">Latest</span>  News</Link>
        </h2>
        
     
    </div>
     </div>
      </div>
    </div>
  )
}

export default Head
